import format from "date-fns/format";
import distanceInWordsToNow from "date-fns/distance_in_words_to_now";

export const iso = date => format(date, "YYYY-MM-DD");

export const distanceInWords = date => {
  if (date < new Date()) return distanceInWordsToNow(date) + " ago";

  return "in " + distanceInWordsToNow(date);
};
