import React, { useState, useEffect } from "react";
import firebase from "@firebase/app";
import "@firebase/auth";
import { Route, Link } from "react-router-dom";

import firestore from "./firestore";

import ForecastList from "./components/ForecastList";
import Forecast from "./components/Forecast";
import ForecastForm from "./components/ForecastForm";

const defaultForecasts = {
  a: {
    id: "a",
    title: "Bitcoin will be worth over $10,000 by next year.",
    createdAt: new Date("2018-11-25"),
    closingAt: new Date(),
    description: "blah blah",
    resolution: null,
    predictions: {
      aa: {
        probability: 2,
        createdAt: new Date("2018-11-26"),
        title: "On second thought...",
      },
      bb: {
        probability: 12,
        createdAt: new Date("2018-11-25"),
        title: "here's why I think this is true",
      },
    },
  },
  b: {
    id: "b",
    title: "Sophia will be over an hour late to Thanksgiving.",
    createdAt: new Date(),
    closingAt: new Date(),
    description: "blah blah",
    resolution: null,
    predictions: {
      aa: {
        probability: 69,
        createdAt: new Date(),
        title: "here's why I think this is true",
      },
    },
  },
};

export default function App() {
  const [db, setDb] = useState(null);
  const [forecasts, setForecasts] = useState(defaultForecasts);

  console.log({ forecasts });

  const [user, setUser] = useState({});
  useEffect(() => {
    const main = async () => {
      let { user } = await firebase.auth().signInAnonymously();
      user = user.toJSON();
      setUser(user);

      const db = await firestore;
      setDb(db);

      db.collection(`users/${user.uid}/forecasts/`)
        .orderBy("closingAt")
        .limit(50)
        .onSnapshot(docs => {
          const forecasts = {};
          docs.forEach(doc => {
            const data = doc.data();
            forecasts[doc.id] = {
              ...data,
              id: doc.id,
              closingAt: data.closingAt.toDate(),
              createdAt: data.createdAt ? data.createdAt.toDate() : null,
            };
          });
          setForecasts(forecasts);
        });
    };
    main();
  }, []);

  return (
    <div className="pt-16">
      <div className="flex bg-blue border-b border-grey-lighter fixed pin-t pin-x z-100 h-16 items-center font-sans p-2">
        <Link
          to="/"
          className="no-underline text-blue-lightest font-bold text-lg"
        >
          HyperForecaster
        </Link>
      </div>

      {/* <pre>uid = {user.uid}</pre> */}

      <Route
        path="/"
        exact
        render={() => (
          <>
            <ForecastList forecasts={forecasts} />
            <Link
              to="/new"
              className="fixed pin-r pin-b m-2 bg-green hover:bg-green-dark text-green-lightest text-4xl font-bold w-16 h-16 rounded-full no-underline justify-center items-center flex text-mono"
            >
              +
            </Link>
          </>
        )}
      />

      <Route
        path="/new"
        render={({ history }) => (
          <ForecastForm
            onSave={forecast => {
              // TODO: do this server-side
              forecast.createdAt = new Date();
              db.collection(`users/${user.uid}/forecasts`)
                .add(forecast)
                .then(docRef => history.push(`/forecasts/${docRef.id}`))
                .catch(error => {
                  console.error("Error adding document: ", error);
                });
            }}
          />
        )}
      />

      <Route
        path="/forecasts/:id"
        render={({
          match: {
            params: { id },
          },
        }) =>
          db ? (
            <Forecast
              forecast={forecasts[id] || {}}
              forecastDoc={db.doc(`users/${user.uid}/forecasts/${id}`)}
              onSavePrediction={prediction => {
                // TODO: do this server-side
                prediction.createdAt = new Date();
                db.collection(`users/${user.uid}/forecasts/${id}/predictions`)
                  .add(prediction)
                  .then(docRef => {})
                  .catch(error => {
                    console.error("Error adding document: ", error);
                  });
              }}
            />
          ) : (
            <p>Loading...</p>
          )
        }
      />
    </div>
  );
}
