import firebase from "@firebase/app";
import "@firebase/firestore";

firebase.initializeApp({
  apiKey: "AIzaSyDjj2Y9GdWrt8wNULRqjQifLCbU4f178rU",
  authDomain: "superforecaster-13d39.firebaseapp.com",
  databaseURL: "https://superforecaster-13d39.firebaseio.com",
  projectId: "superforecaster-13d39",
  storageBucket: "superforecaster-13d39.appspot.com",
  messagingSenderId: "373709836753",
});

firebase.firestore().settings({ timestampsInSnapshots: true });

export default firebase
  .firestore()
  .enablePersistence()
  .then(() => firebase.firestore())
  .catch(error => firebase.firestore());
