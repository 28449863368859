import React, { useState } from "react";
import Textarea from "react-textarea-autosize";
import { Label } from "./ForecastForm";

export default ({ defaultProbability = 50, onSave }) => {
  const [title, setTitle] = useState("");
  const [probability, setProbability] = useState(defaultProbability);
  const [submitting, setIsSubmitting] = useState(false);

  return (
    <form
      className="rounded border p-4 mb-4"
      onSubmit={e => {
        e.preventDefault();
        if (defaultProbability === probability) return;

        onSave({ title, probability });
      }}
    >
      <Label id="probability">Probability</Label>
      <input
        id="probability"
        type="number"
        min={0}
        max={100}
        value={probability}
        onChange={e => setProbability(parseFloat(e.target.value))}
        className="text-4xl shadow appearance-none border rounded w-full py-2 px-3 text-black leading-tight focus:outline-none focus:shadow-outline mb-4"
      />

      <Label id="title">Note</Label>
      <Textarea
        id="title"
        type="text"
        placeholder="Ex: I think I was too confident before"
        value={title}
        onChange={e => setTitle(e.target.value)}
        className="shadow appearance-none border rounded w-full py-2 px-3 text-grey-darker leading-tight focus:outline-none focus:shadow-outline mb-4"
      />

      {defaultProbability !== probability && (
        <button
          type="submit"
          className={
            "bg-green hover:bg-green-dark text-green-lightest font-bold p-3 rounded font-sans w-full text-xl" +
            (submitting ? " cursor-not-allowed" : "")
          }
        >
          Update Forecast
        </button>
      )}
    </form>
  );
};
