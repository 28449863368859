import React, { useState, useEffect } from "react";
import { iso, distanceInWords } from "../utils";

import PredictionForm from "./PredictionForm";

const Prediction = ({ prediction: { id, probability, createdAt, title } }) => {
  return (
    <div className="flex flex-row justify-between p-4 rounded border -mb-1 bg-white">
      <div className="text-base flex-1 text-grey-darker">
        <div className="block mb-2 font-bold">
          {iso(createdAt)}
          <span className="text-grey ml-1 font-normal">
            {distanceInWords(createdAt)}
          </span>
        </div>
        {title}
      </div>
      <span className="text-4xl ml-2">{probability}%</span>
    </div>
  );
};

export default ({
  forecast: {
    id,
    title,
    createdAt,
    closingAt,
    description,
    resolution,
    // predictions,
  },
  forecastDoc,
  onSavePrediction,
}) => {
  const [predictions, setPredictions] = useState({});

  const recentProbability = (Object.values(predictions)[0] || {}).probability;
  console.log({ recentProbability });

  useEffect(() => {
    return forecastDoc
      .collection("predictions")
      .orderBy("createdAt", "desc")
      .limit(50)
      .onSnapshot(docs => {
        const predictions = {};
        docs.forEach(doc => {
          const data = doc.data();
          predictions[doc.id] = {
            ...data,
            id: doc.id,
            createdAt: data.createdAt.toDate(),
          };
        });
        setPredictions(predictions);
      });
  }, []);

  return (
    <div className="p-6">
      <h2 className="font-sans mb-4">
        {title}
        {createdAt && (
          <small className="float-right text-grey-dark font-normal text-base">
            made {distanceInWords(createdAt)}
          </small>
        )}
      </h2>
      {description && <p className="mb-4">{description}</p>}

      <PredictionForm
        key={recentProbability}
        defaultProbability={recentProbability}
        onSave={onSavePrediction}
      />

      {Object.entries(predictions || {}).map(([id, prediction]) => (
        <Prediction key={id} prediction={prediction} />
      ))}
    </div>
  );
};
