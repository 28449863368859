import React from "react";
import { Link } from "react-router-dom";
import { iso, distanceInWords } from "../utils";

import distanceInWordsToNow from "date-fns/distance_in_words_to_now";

const ListItem = ({
  forecast: {
    id,
    title,
    createdAt,
    closingAt,
    description,
    resolution,
    predictions,
  },
  onPress,
}) => {
  // TODO: sort?
  const recentPrediction = Object.values(predictions || {})[0];
  const probability = (recentPrediction || {}).probability;

  return (
    <Link
      className="flex flex-row justify-between p-4 border-b no-underline text-black hover:bg-blue-lightest"
      to={`/forecasts/${id}`}
    >
      <div className="text-base flex-1">
        {createdAt && (
          <span className="block mb-1">
            {iso(createdAt)}{" "}
            <span className="text-grey ml-1 font-normal">
              {distanceInWords(createdAt)}
            </span>
          </span>
        )}
        <span className="font-bold text-lg font-sans">{title}</span>
      </div>
      {probability && <span className="text-4xl ml-2">{probability}%</span>}
    </Link>
  );
};

export default ({ forecasts }) => {
  if (!forecasts || Object.keys(forecasts).length == 0)
    return (
      <span>
        Make your first forecast with the plus button in the bottom right!
      </span>
    );

  return Object.entries(forecasts).map(([id, forecast]) => (
    <ListItem key={id} forecast={forecast} />
  ));
};
