import React, { useState, useEffect, useRef } from "react";
import Textarea from "react-textarea-autosize";
import addWeeks from "date-fns/add_weeks";
import { iso } from "../utils";

export const Label = ({ children, id }) => (
  <label
    className="block text-grey-darker text-sm font-bold mb-2 font-sans"
    htmlFor={id}
  >
    {children}
  </label>
);

export default ({ onSave }) => {
  const [title, setTitle] = useState("");
  const [closingAt, setClosingAt] = useState(iso(addWeeks(new Date(), 1)));
  const [submitting, setIsSubmitting] = useState(false);

  const titleTextarea = useRef(null);

  // useEffect(() => titleTextarea.current.focus());

  return (
    <form
      onSubmit={e => {
        e.preventDefault();
        setIsSubmitting(true);
        onSave({ title, closingAt: new Date(closingAt) });
      }}
      className="p-4"
    >
      <fieldset disabled={submitting}>
        <Label id="title">Forecast Prediction</Label>
        <Textarea
          id="title"
          ref={titleTextarea}
          type="text"
          placeholder="Ex: No one will mashed potatoes to Thanksgiving dinner."
          value={title}
          onChange={e => setTitle(e.target.value)}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-grey-darker leading-tight focus:outline-none focus:shadow-outline mb-4"
        />

        <Label id="closingAt">Closing Date</Label>
        <input
          id="closingAt"
          type="date"
          value={closingAt}
          onChange={e => setClosingAt(e.target.value)}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-grey-darker leading-tight focus:outline-none focus:shadow-outline mb-4"
        />

        {title.length > 3 && (
          <button
            type="submit"
            className={
              "bg-green hover:bg-green-dark text-green-lightest font-bold py-4 px-4 rounded font-sans w-full text-2xl fixed pin-b pin-l pin-r" +
              (submitting ? " cursor-not-allowed" : "")
            }
          >
            Mak{submitting ? "ing" : "e"} Forecast{submitting ? "..." : ""}
          </button>
        )}
      </fieldset>
    </form>
  );
};
